import BaseService, { APIResponse } from "./BaseService";

export interface Guadagni{
    importoEntrate: number
    importoUscite: number
}

export interface IBodyUsciteEntrate{
    categoria: number
    descrizione: string
    prezzo: number
    tipo: string
    data: string
}

export default class EntrateUsciteServices extends BaseService {
    static async ListaEntrateUscite(idStruttura: string, fromDate: string, toDate: string){
        return await this.perform({
            url: 'operatore/entrateuscite/'+ idStruttura,
            method: 'get',
            params: {from_date: fromDate, to_date: toDate}
        })
    }
    static async Guadagni(idStruttura: string, fromDate: string, toDate: string): Promise<APIResponse<Guadagni>>{
        return await this.perform({
            url: 'operatore/entrateuscite/guadagni/'+ idStruttura,
            method: 'get',
            params: {from_date: fromDate, to_date: toDate}
        })
    }

    static async AggiungiOperazione(idStruttura: string, body: IBodyUsciteEntrate){
        return await this.perform({
            url: 'operatore/entrateuscite/'+ idStruttura,
            method: 'post',
            body: body
        })
    }

    static async GetCategorie(){
        return await this.perform({
            url: 'operatore/entrateuscite/categorie',
            method: "GET"
        })
    }

    static async deleteOperazione(idStruttura: string, id: number){
        return await this.perform({
            url:'operatore/entrateuscite/'+ idStruttura+'/'+id,
            method: 'delete'
        })
    }
}
