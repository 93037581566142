<script>
import { Doughnut } from 'vue-chartjs'
import {Watch} from "vue-property-decorator";

export default {
  extends: Doughnut,
  props: ['chartdata', 'options','cambio'],
  data(){
    return{
  }
  },

  watch:{
    cambio(newVal){
      this.renderChart(this.chartdata, this.options)
    },
  },


  mounted () {
    this.renderChart(this.chartdata, this.options)
  }
}
</script>

<style>
</style>
