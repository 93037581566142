











































































































































































































































































































import Vue from "vue";
import {Component, Provide, Watch} from "vue-property-decorator";
import Toolbar from "@/components/Toolbar.vue";
import {Filtro} from "@/Classes/FiltroClass";
import StruttureService, {StruttureResponse} from "@/services/StruttureService";
import SportService from "@/services/SportService";
import CampiService from "@/services/CampiService";
import Doughnut from "@/components/Doughnut.vue";
import PrenotazioneService, {Classifica} from "@/services/PrenotazioneService";
import {DateTime} from "luxon";
import {PrenotazioneModel} from "@/Classes/PrenotazioneClass";
import EntrateUsciteServices from "@/services/EntrateUsciteServices";

@Component({
  components: {
    Toolbar, Doughnut
  },
})

export default class Summary extends Vue {
  public filtro = new Filtro();
  public datiStruttura: StruttureResponse = []
  public date: Date[] = []
  public sport: any = null
  public listaSport: any = []
  public listaCampi: any = [];
  public incassiLordi: number = 0
  public incassiTotali: number = 0;
  public valoreScontiOperatore: number = 0
  public valoreSconto: number = 0
  public valorePromozione: number = 0
  public incassiApp: number = 0;
  public numeroPrenotazioni: number = 0
  public numeroPrenotazioniApp: number = 0
  public numeroPrenotazioniStruttura: number = 0
  public numeroPrenotazioniAnnullate: number = 0
  public classifica: Classifica[] = []
  public cambio: boolean = false;
  public dialogIncassi: boolean = false
  public dialogEntrate: boolean = false
  public dialogPrenotazioni: boolean = false
  public listaPrenotazioni: PrenotazioneModel[]= []
  public listaEntrateUscite: any = []
  public guadagniEntrateUscite: number = 0
  public importoEntrate: number = 0
  public importoUscite: number = 0
  public listaDettaglioEU: any = []
  public search: string = ""

  public chartPrenotazioni: any = {
    labels: ['Struttura', 'App'],
    datasets: [
      {
        label: 'Data One',
        backgroundColor: ['#f87979', '#2e5eec'],
        data: [0, 0]
      }
    ]
  }

  public chartIncassi: any = {
    labels: ['Struttura', 'App'],
    datasets: [
      {
        label: 'Data One',
        backgroundColor: ['#f87979', '#2e5eec'],
        data: [0, 0]
      }
    ]
  }


  public options: any = {
    responsive: true,
    maintainAspectRatio: false
  }


  public tableData: any = [{
    posizione: '3',
    nome: 'Test',
    prenotazioni: '200',
    sconti: '1'
  }, {
    posizione: '5',
    nome: 'Test',
    prenotazioni: '104',
    sconti: '0'
  }, {
    posizione: '4',
    nome: 'Test',
    prenotazioni: '137',
    sconti: '1'
  }, {
    posizione: '2',
    nome: 'Test',
    prenotazioni: '266',
    sconti: '4'
  }, {
    posizione: '1',
    nome: 'Test',
    prenotazioni: '350',
    sconti: '3'
  }]

  public apriDialogIncassi() {
    this.dialogIncassi = !this.dialogIncassi
  }

  public async apriDialogEntrateUscite() {
    this.dialogEntrate = !this.dialogEntrate
  }

  public async apriDialogPrenotazioni() {
    this.dialogPrenotazioni = !this.dialogPrenotazioni

    const response = await PrenotazioneService.getPrenotazione(this.filtro)
    if(response.error){
      this.$message.error(response.msg)
    } else {
      this.listaPrenotazioni = response.data
      for(let i = 0; i<this.listaPrenotazioni.length; i++){
        this.listaPrenotazioni[i].data_ora = DateTime.fromISO(this.listaPrenotazioni[i].data_ora.toString()).toUTC().toFormat("dd/MM/yyyy HH:mm")
        this.listaPrenotazioni[i].importo /= 100
        this.listaPrenotazioni[i].importo_sconto /= 100
        this.listaPrenotazioni[i].importo_promozione /= 100
        this.listaPrenotazioni[i].importo_sconto_operatore /= 100
      }
    }
  }

  public async RiceviStrutture() {
    try {
      const response = await StruttureService.getStrutture();

      if (response.error) {
        this.$message.error(response.msg)
      } else {
        this.datiStruttura = response.data;
        this.filtro.id_struttura = this.datiStruttura[0].struttura.value
        this.getDati(false)
      }
    } catch (err: any) {

    }
  }

  public async RiceviEntrateUscite(){

    this.listaEntrateUscite = []
    this.listaDettaglioEU = []
    const response = await EntrateUsciteServices.ListaEntrateUscite(this.filtro.id_struttura, this.filtro.from_date, this.filtro.to_date)
    if(response.error){
      this.$message.error(response.msg)
    } else {
      this.listaEntrateUscite = response.data
      for(let i = 0; i<this.listaEntrateUscite.length; i++){
        this.listaEntrateUscite[i].data = DateTime.fromISO(this.listaEntrateUscite[i].data.toString()).toUTC().toFormat("dd/MM/yyyy")
        this.listaEntrateUscite[i].prezzo /= 100
        this.listaEntrateUscite[i].tipo = this.listaEntrateUscite[i].tipo.toUpperCase()
      }
      const idCategorie = [...new Set(this.listaEntrateUscite.map((item: any) => item.categoria.categoria))]

      for(let i=0; i<idCategorie.length; i++){
        this.listaDettaglioEU.push({categoria: idCategorie[i], entrate: 0, uscite: 0})
      }

      for(let i = 0; i<this.listaEntrateUscite.length; i++){
        for (let j=0; j<this.listaDettaglioEU.length; j++){
          if(this.listaEntrateUscite[i].categoria.categoria === this.listaDettaglioEU[j].categoria){
            if(this.listaEntrateUscite[i].tipo === "ENTRATA"){
              this.listaDettaglioEU[j].entrate += this.listaEntrateUscite[i].prezzo
            } else {
              this.listaDettaglioEU[j].uscite += this.listaEntrateUscite[i].prezzo
            }
          }
        }
      }
      console.log(this.listaDettaglioEU)
/*
      const riepilogo = this.listaEntrateUscite.map((item: any) => {
        return {
          categoria: item.categoria,
          tipo: item.tipo,
          importo: item.prezzo
        }
      })*/
    }
  }

  public async deleteEntrataUscita(item: any){
    this.$confirm("Una volta eliminata questa operazione non sarà più visualizzabile", "Sei sicuro di voler eliminare l'operazione'?", {
      confirmButtonText: "Conferma",
      cancelButtonText: "Indietro"
    }).then(async () => {
      const response = await EntrateUsciteServices.deleteOperazione(this.filtro.id_struttura, item.id)
      if (response.error) {
        this.$message.error(response.msg)
      } else {
        this.$message.success("Eliminazione avenuta");
        await this.getDati()
      }
    }).catch(() => {
      this.$message({
        type: 'warning',
        message: 'Errore!'
      })
    })
  }

  async FiltraStrutture() {
    console.log("DATA INIZIALE", this.date)
    this.filtro.from_date = this.date[0]
    this.filtro.to_date = this.date[1]
    console.log(this.filtro.from_date)
    this.getDati()
  }

  async getDati(error: boolean = true) {
    const response2 = await PrenotazioneService.NumeroPrenotazioni(this.filtro.id_struttura, this.filtro)
    if (!response2.error) {
      this.numeroPrenotazioni = response2.data
    }
    const response3 = await PrenotazioneService.NumeroPrenotazioniApp(this.filtro.id_struttura, this.filtro)
    if (!response3.error) {
      this.numeroPrenotazioniApp = response3.data
      this.chartPrenotazioni.datasets[0].data[0] = this.numeroPrenotazioni - this.numeroPrenotazioniApp
      this.chartPrenotazioni.datasets[0].data[1] = this.numeroPrenotazioniApp
      this.cambio = !this.cambio;
      this.numeroPrenotazioniStruttura = this.numeroPrenotazioni - this.numeroPrenotazioniApp
    }
    const responseAnnullate = await PrenotazioneService.NumeroPrenotazioniAnnullate(this.filtro.id_struttura, this.filtro)
    if (!responseAnnullate.error) {
      this.numeroPrenotazioniAnnullate = responseAnnullate.data
    }
    const responseIncassi = await PrenotazioneService.GuadagniTotali(this.filtro.id_struttura, this.filtro)
    if (!responseIncassi.error) {
      this.incassiTotali = responseIncassi.data.importo_totale / 100
      this.valoreScontiOperatore = responseIncassi.data.importo_sconto_operatore / 100
      this.valoreSconto = responseIncassi.data.importo_sconto / 100
      this.valorePromozione = responseIncassi.data.importo_promozione / 100
      this.incassiApp = responseIncassi.data.importo_app / 100
      this.incassiLordi = this.incassiTotali + this.valoreScontiOperatore + this.valoreSconto + this.valorePromozione
      this.chartIncassi.datasets[0].data[0] = this.incassiTotali - this.incassiApp
      this.chartIncassi.datasets[0].data[1] = this.incassiApp
      this.cambio = !this.cambio;
    }

    const guadagniTotali = await EntrateUsciteServices.Guadagni(this.filtro.id_struttura, this.filtro.from_date, this.filtro.to_date)
    if(!guadagniTotali.error){
      this.guadagniEntrateUscite = (guadagniTotali.data.importoEntrate - guadagniTotali.data.importoUscite) / 100
      this.importoEntrate = guadagniTotali.data.importoEntrate / 100
      this.importoUscite = guadagniTotali.data.importoUscite / 100

    }
    let boolErrore = response2.error || response3.error || responseIncassi.error || responseAnnullate.error
    if(error && boolErrore){
      this.$message.error(responseAnnullate.msg)
    }
    await this.riceviClassifica()
    await this.RiceviEntrateUscite()

  }

  @Watch('filtro.id_sport')
  async strutturaSelezionataWatch(newVal: number) {
    if (this.numeroPrenotazioni != 0) {
      const responseCampi = await CampiService.ListaCampi(this.filtro.id_struttura, this.filtro.id_sport);
      this.listaCampi = responseCampi.data.map(x => {
        return {
          label: x.nome,
          value: x.id,
          color: x.color
        }
      }).sort(function (a, b) {
        let nameA = a.label.toUpperCase(); // ignore upper and lowercase
        let nameB = b.label.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;
      });
    }
  }

  async riceviClassifica() {
    const response = await PrenotazioneService.ClassificaUtenti(this.filtro.id_struttura, this.filtro)
    if (response.error) {
      this.$message.error(response.msg)
    } else {
      this.classifica = response.data
    }
  }

  public async AzzeraSport() {
    this.filtro.id_sport = null;
  }

  public async AzzeraCampo() {
    this.filtro.id_campo = null
  }

  public async AzzeraOra() {
    this.filtro.from_date = null
    this.filtro.to_date = null
    this.date = []
  }

  public indexMethod(index: any) {
    return ++index;
  }

  async beforeMount() {
    this.filtro.from_date = DateTime.now().startOf('month').toJSDate()
    this.filtro.to_date = DateTime.now().set({hour: 23, minute:59, second: 59}).toJSDate()
    this.date[0] = DateTime.now().startOf('month').toJSDate()
    this.date[1] = DateTime.now().set({hour: 23, minute:59, second: 59}).toJSDate()
  }

  async mounted() {
    await this.RiceviStrutture()
    await this.RiceviEntrateUscite()
    const response = await SportService.ListaSportStruttura(2)
    this.listaSport = response.data.map(x => {
      return {
        nome: x.nome,
        id: x.id,
      }
    })
  }

}
